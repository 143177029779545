$mdc-theme-primary: #b11a3b;
$mdc-theme-primary-light: #639BE7;
$mdc-theme-primary-dark: #4475B7;
$mdc-theme-secondary: #b11a3b;
$mdc-theme-secondary-light: #639BE7;
$mdc-theme-secondary-dark: #4475B7;
$mdc-theme-background: #f6f6f6;
$mdc-typography-font-family: 'Open Sans', sans-serif;

$mdc-layout-grid-breakpoints: (
        desktop: 1024px,
        tablet: 480px,
        phone: 0px
);

// Flexperto custom properties
$flx-border-radius: 8px;
$flx-border-plain: #eaeaea;
$flx-border-primary: $mdc-theme-primary;
$flx-theme-success: #6ECC94;
$flx-theme-danger: #ff4858;
$flx-theme-warning: #FFD74D;
$flx-theme-text: #57626B;
$flx-theme-background: #F7F8FA;

$flx-theme-phone-accept: #4CD964;
$flx-theme-phone-decline: #F53333;
$flx-theme-phone-message: $flx-theme-text;